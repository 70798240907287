import { combineReducers, createSlice, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import logger from "redux-logger";
import moment from "moment";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
  },
  reducers: {
    setAuth: (state, action) => {
      const { user, token } = action.payload;
      return { ...state, user, token };
    },
  },
});

const createJourneySlice = createSlice({
  name: "journeyform",
  initialState: {
    fromText: "",
    toText: "",
    startingPoint: "",
    endPoint: "",
    fromLoading: false,
    toLoading: false,
    vehicleType: "bike",
    mobile_Number: "",
    description: "",
    date: moment().format("DD-MM-YYYY HH-mm"),
  },
  reducers: {
    setForm: (state, action) => {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    },
    updateForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetForm: (state, action) => {
      return {
        fromText: "",
        toText: "",
        startingPoint: "",
        endPoint: "",
        fromLoading: false,
        toLoading: false,
        vehicleType: "bike",
        mobile_Number: "",
        description: "",
        date: moment().format("DD-MM-YYYY HH-mm"),
      };
    },
  },
});

export const { setUser, setToken, setAuth } = authSlice.actions;
export const { setForm, resetForm, updateForm } = createJourneySlice.actions;

const authReducer = authSlice.reducer;
const journeyformReducer = createJourneySlice.reducer;

const prisistConfig = {
  key: "root",
  storage,
};
const reducers = combineReducers({
  auth: authReducer,
  journeyform: journeyformReducer,
});
const persistedReducer = persistReducer(prisistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: [logger],
});
