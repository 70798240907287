import { gql } from "@apollo/client";

const GET_JOURNEY = gql`
  query getJourney($id: ID!) {
    getJourney(id: $id) {
      _id
      userId
      status
      startingPoint
      endPoint
      from {
        name
      }
      endPoint
      to {
        name
      }
      date
      createdAt
      updatedAt
      mobile_Number
      description
      vehicleType
      user{
        logo
        name
        email
      }
    }
  }
`;
export default GET_JOURNEY;
