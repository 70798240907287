import { gql } from "@apollo/client";

const GET_JOURNEYS = gql`
  query getJourneys {
    getJourneys {
      _id
      userId
      mobile_Number
      description
      date
      user {
        name
        email
        logo
      }
      status
      startingPoint
      from {
        name
      }
      endPoint
      to {
        name
      }
      vehicleType
      createdAt
      updatedAt
    }
  }
`;
export default GET_JOURNEYS;
