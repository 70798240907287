import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import store from "../Redux/store";

const uri =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:5008/graphql"
    : "https://graphql.publicdrop.in/graphql";
const httpLink = createHttpLink({
  uri: uri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem("token");
  const { token } = store.getState().auth;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
