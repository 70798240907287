import { useQuery } from "@apollo/client";
import React from "react";
import GET_JOURNEYS from "../apollo/query/getJourneys";
import Banner from "./Banner";
import LowerComponent from "./LowerComponent";
import Navbar from "./Navbar";

const JourneyList = () => {
  const { loadings, data: journeysData } = useQuery(GET_JOURNEYS);
  if (loadings) {
    return <h1>Loading...</h1>;
  }
  const journeys = journeysData?.getJourneys || [];
  console.log({ journeysData });
  return (
    <div> 
      <Banner
      size="text-2xl"
        firstLine={"Send Your Parcel"}
        secondLine={"Anywhere Across India"}
      />
      <div className=" space-x-20 w-maxx mx-auto py-8">
        <h1 className="text-3xl font-semibold">Ongoing Journeys : </h1>
      </div>
      <hr />
      <div className="flex flex-col-reverse pt-8">
        {journeys.map((journey) => {
          return (
            <div key={journey._id}>
              <LowerComponent journey={journey} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JourneyList;
