import React from 'react'

const DropYourBoxButton = () => {
    return (
      <button
      type="submit"
      className="bg-main text-white font-semibold  md:text-lg sm:text-sm text-sm md:px-4  px-5 py-3 rounded-full shadow-2xl mt-2">
        Drop Your Box
    </button>
    )
}

export default DropYourBoxButton
