import React from 'react'
import Navbar from '../Components/Navbar'

const ContactUs = () => {
    return (
        <>
        <Navbar/>
         <div className="lg:px-40 md:px-30 sm:px-20 px-10   py-10">
             
             <h2 className="flex text-red-600 justify-center">Public Drop</h2>  
             <h1 className="">Contact Us</h1>
             <div className="shadow-md border-2 border-gray-300 rounded-2xl px-8 py-14">
                 <form target="_blank" action="https://formsubmit.co/tahsinchouhan2000@gmail.com" method="POST">
             <div className=" flex sm:flex-row flex-col sm:space-y-0 space-y-6 justify-evenly">
                 <input className="px-10 py-4 border-2 md:w-1/4 1/2 rounded-xl shadow-sm" type="text" name="name" required id="" placeholder="Full Name" />
                 <input className="px-10 py-4 border-2 md:w-1/4 1/2 rounded-xl shadow-sm" type="email" name="email" required id="" placeholder="Email Adress" />
                 <input className="px-10 py-4 border-2 md:w-1/4 1/2 rounded-xl shadow-sm" type="number" name="number" required id="" placeholder="Mobile Number " />
             </div>
             <div className="lg:px-20 md:px-15 sm:px-10 py-14">
                 <textarea className="px-10 border-2 py-4 w-full  rounded-xl shadow-sm" name="description" required placeholder="Enter Description" id=""  rows="5"></textarea>
             </div>
             <div className="flex sm:justify-end justify-center ">
             <button className="bg-main px-8 py-4 rounded-full text-base font-semibold hover:bg-gray-500- text-white" type="submit">Get A Quote</button>
             </div>
                 </form>
             </div>
         </div>
        </>
    )
}

export default ContactUs
