import React from "react";
import { VscLocation } from "react-icons/vsc";
import { BsArrowRight } from "react-icons/bs";
import { useQuery } from "@apollo/client";
import GET_JOURNEY from "../apollo/query/getJourney";
import { useParams } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useState } from "react";


const Details = () => {
  const params = useParams();
  console.log({ params });
  const { journeyId } = params;
  const [state, setstate] = useState("Copy Number")
  const { loading, data,error } = useQuery(GET_JOURNEY,{
    variables:{
      id:journeyId
    }
  });

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if(error){
    return <p>{error.message}</p>
  }
  const journey = data?.getJourney || {};
  const { user, from, to, vehicleType, date, mobile_Number,description } = journey;


  console.log(journey)
  return (
    <>
      <Navbar />
    <div className="mt-40">
      <div className="flex flex-col h-1/3 justify-center">
        <div className="flex flex-col sm:flex-1  justify-center items-center p-2">
          <img src={user.logo} alt={user.name} className="rounded-full md:w-40 md:h-40 "  />
            <div className="md:text-xl text-lg mt-3">{user.name}</div>
            <div className="md:text-xl text-sm  text-gray-500">{user.email}</div>
        </div>
        
        <div className="sm:px-20 px-8 space-y-4">
        <div className="sm:flex   md:space-x-6  ">
      <div>

          <h1 className="flex sm:text-4xl  text-xl items-end">Journey : </h1>
      </div>
      <div className="flex   md:space-x-6 space-x-3">

                  <div className="flex lg:text-3xl sm:text-xl text-lg  font-normal  space-x-1 items-center">
              <span className="sm:text-4xl text-3xl text-gray-500">
                <VscLocation />
              </span>{" "}
              <p className="text-gray-400 md:text-2xl sm:text-lg pt-3 text-sm font-semibold">
                {from?.name}
              </p>
            </div>
            <div className="h-full flex items-center  space-x-4">
              <span className="sm:text-5xl text-gray-400 pt-4 py-2 text-4xl">
                <BsArrowRight />
              </span>
            </div>
            <div className="flex lg:text-3xl sm:text-xl text-lg  font-normal  space-x-1 items-center">
              <span className="sm:text-4xl text-3xl text-gray-500">
                <VscLocation />
              </span>
              <p className="text-gray-400 md:text-2xl sm:text-lg pt-3 text-sm font-semibold">
                {to?.name}
              </p>
            </div>
            <div className="sm:w-16 w-12 sm:h-10 h-6">
              <img src={`/assets/images/${vehicleType}.png`} alt="" />
            </div>
          </div>
          </div>
         <div className="flex m-0 ">
           <div>

           <h3 className="text-lg m-0">Description: </h3> 
           </div>
           &ensp;
           <div>

           <p className="flex items-end m-0 ">
           {description}
           </p>
           </div>
         </div>
         <div className=" flex flex-row ">
         <h3 className="text-lg" >
                      Mobile Number:  &ensp;  
                  </h3> 
                   <p className=" flex m-0 mt-1"> 
                      {mobile_Number}
                     </p>
         </div>
         <div>
         <CopyToClipboard text={mobile_Number}>
                    <button
                      className="bg-main  py-3 px-8 rounded-md text-white"
                      onClick={() => setstate("Copied!")}
                    >
                      {state}
                    </button>
                  </CopyToClipboard>
         </div>
       
       
        </div>
      </div>
      
    </div>
    </>
  );
};

export default Details;
