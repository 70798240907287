import React from "react";
import Radium, { StyleRoot } from "radium";

// const styles = {
//   bounce: {
//     animation: "x 3s",
//     animationName: Radium.keyframes(bounce, "bounce"),
//   },
// };

const Banner = (props) => {
  const { firstLine, secondLine,size } = props;

  return (
    <StyleRoot>
    <div
      className="banner-bg h- px-5 flex flex-col"
      style={{
        // height:"80vh",
        // backgroundImage: "url('/assets/images/delivery5.jpg')",
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "0 0 0 0",
        backgroundSize: "100%",
      }}
    >
      <div className="pb-14 pt-20  flex flex-col md:w-eighty md:mx-auto justify-center lg:space-x-8 sm:space-x-6  space-x-3 ">
    {/* <img
      src="/assets/images/parcel.png"
      alt="Developer"
      className="xl:w-96 xl:h-96 lg:w-72 lg:h-72 md:w-64 md:h-64 sm:w-56 sm:h-56 w-36 h-36 "
      style={styles.bounce}
    /> */}

        <div className="flex  justify-center space-y-1 z-10">
          <h1 className="text-3xl font-normal ">
            <span className={`text-main xl:text-7xl lg:text-6xl md:text-5xl ${size} md:font-semibold font-bold`}>
            {firstLine}
            </span>
          </h1>
          <h2 className=" xl:text-5xl flex items-center pb-3 lg:text-3xl md:text-2xl sm:text-xl text-base font-bold sm:ml-4 ml-3">
            {secondLine}
          </h2>
        </div>
    <img src="/assets/images/delivery5.jpg" alt="" className=""/>
      </div>
      </div>
    </StyleRoot>
  );
};

export default Banner;
