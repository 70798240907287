import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import GET_JOURNEYS from "../apollo/query/getJourneys";
import LowerComponent from "./LowerComponent";
import { useLazyQuery } from "@apollo/client";
import GET_PLACES from "../apollo/query/getPlaces";
import JourneyList from "./JourneyList";
import { Link } from "react-router-dom";
import Blogs from "./Blogs";

const LowerSection = () => {
  const [state, setState] = useState({
    fromText: "",
    toText: "",
    startingPoint: "",
    endPoint: "",
    fromLoading: false,
    toLoading: false,
    vehicleType: "bike",
    mobile_Number: "",
    description: "",
    date: "",
  });
  const [getPlaces, { data: placeData }] = useLazyQuery(GET_PLACES, {
    onError: (error) => {
      console.log(error);
    },
  });

  // const [insertJourney, { data }] = useMutation(INSERT_JOURNEY, {
  //   onCompleted: (response) => {
  //     console.log("journey has been created");
  //     setState({
  //       ...state,
  //       startingPoint: "",
  //       endPoint: "",
  //       mobile_Number: "",
  //       date:"",
  //       vehicleType: "motorbike",
  //     });
  //   },
  // });

  useEffect(() => {
    const variables = {
      page: 1,
      limit: 10,
    };

    if (state.fromText !== "") {
      variables.search = state.fromText;
    }
    if (state.toText !== "") {
      variables.search = state.toText;
    }

    console.log(variables);
    getPlaces({
      variables,
    });
  }, [state.fromText, state.toText]);

  const results = placeData?.getPlaces || [];

  return (
    <div>
      <div className="flex z-10  justify-center space-x-8 pb-20">
        <div>

        <Link
          to="/create"
          className="text-white sm:px-14 px-4 md:text-xl sm:text-base bg-main shadow text-sm md:py-3 py-2 font-semibold rounded-lg"
          >
          <button>Join our Network</button>
        </Link>
          </div>
          <div>

        <Link
          to="/send"
          className="text-white sm:px-12 px-4 md:text-xl sm:text-base bg-main shadow text-sm md:py-3 py-2 font-semibold rounded-lg"
          >
          <button>Send Your Parcel</button>
        </Link>
          </div>
      </div>
      <div className="lg:px- px-10 space-y-16 ">
        <h1 className="text-center sm:text:4xl text-3xl pt-10">
          Why Public Drop 
        </h1>
        <div className="sm:flex md:flex-row  flex-col md:space-y-0 space-y-8 justify-between lg:justify-evenly">
          <Blogs src={"coorier"} headLine={"The End Of Coorier Service"} 
          content={`Now you can send your parcel anywhere across India in a very low price by uing Public Drop. "We Connect People"`}
          />
          <Blogs src={"helping"} headLine={"Start Helping Each Other"}
          content={`Now By taking people's parcel while we travel is a great chance of helping each otherand now this is possible by Public Drop. `}
          />
          <Blogs src={"travel"} headLine={"Get Paid While You Travel"}
          content={`Now you can get paid while you are travelling just by taking someone's parcel with you. Create your journey now  `}
          />
        </div>
        <div className="flex md:flex-row  flex-col sm:space-y-0 space-y-8  justify-between lg:justify-evenly">
          <Blogs src={"coorier"} headLine={"Customer First"} 
          content={`We look at everything from your point of veiw. Costomer satisfaction is our frist priority `}
          />
          <Blogs src={"helping"} headLine={"Start Helping Each Other"}
          content={`Now By taking people's parcel while we travel is a great chance of helping each otherand now this is possible by Public Drop. `}
          />
          <Blogs src={"travel"} headLine={"Get Paid While You Travel"}
          content={`Now you can get paid while you are travelling just by taking someone's parcel with you. Create your journey now  `}
          />
        </div>
      </div>
    </div>
  );
};

export default LowerSection;
