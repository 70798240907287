import { gql } from "@apollo/client";

const GOOGLE_SIGNIN = gql`
  mutation googleSignIn(
    $name: String
    $logo: String
    $email: String!
    $googleId: ID!
  ) {
    googleSignIn(name: $name, logo: $logo, email: $email, googleId: $googleId) {
      payload {
        _id
        name
        email
        logo
        googleId
      }
      status
      token
      message
    }
  }
`;

export default GOOGLE_SIGNIN;
