import React from "react";
import UpperSection from "../Components/UpperSection";
import LowerSection from "../Components/LowerSection";
import Footer from "../Components/Footer";
const Home = () => {
  return (
    <div>
      <UpperSection />
      <LowerSection />
      <Footer />
    </div>
  );
};

export default Home;
