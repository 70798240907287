import React from "react";
import { Link } from "react-router-dom";
import LoginButton from "./LoginButton";

const Navbar = () => {
  return (
    <nav className="fixed bg-white top-0 shadow-lg right-0 left-0 z-20 ">
      <div className="flex justify-between md:px-24 px-6 py-2 w-full ">
        <Link to="/" className="text-black hover:text-black">
        <div className="flex justify-center items-center space-x-2">
          <img
            src="/newlogo.png"
            alt=""
            className="sm:w-14 w-12 sm:h-14 h-14"
          />
          <h1 className=" lg:text-3xl sm:text-2xl text-xl m-0 font-semibold ">
            publicdrop<span className="text-main">.in</span>{" "}
          </h1>
        </div>
        </Link>

        <div className="flex justify-center items-center">
        {/* <Link
          to="/"
          className="text-white sm:px-10 px-4 sm:text-base bg-main shadow text-sm md:py-3 py-2 font-semibold rounded-full"
        >
          <button>Register Now</button>
        </Link> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
