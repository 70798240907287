import React from 'react'
import Banner from '../Components/Banner'
import Navbar from '../Components/Navbar'

const Support = () => {
    return (
        <div>
            <Navbar/>
            <Banner/>
            <div className="lg:px-30 md:px-20 sm:px-10 px-8">
            <h1 className="text-3xl font-semibold">Connect with Us</h1>
            <p>You can contact us or directly chat with us by clicking chat button below</p>
            </div>
        </div>
    )
}

export default Support
