import React from "react";
import Navbar from "../Components/Navbar";
const HomeLayout = (props) => {
  return (
    <div>
      <Navbar />
      <div style={{ marginTop: "63px" }}>{props.children}</div>
    </div>
  );
};
export default HomeLayout;
