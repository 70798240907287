import React from "react";
import { VscLocation } from "react-icons/vsc";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import DropYourBoxButton from "./DropYourBoxButton";




const JourneyListItem = (props) => {
  const { journey } = props;
  const { _id,user, from, to, vehicleType, date,description } = journey;
  
 
  return (
    <div >
      <div className="hidden"></div>
      <div className=" sm:space-y-5 space-y-0  rounded-md lg:w-seventy md:w-maxx w-ninty mx-auto lg:px-6 px-3 py-3 sm:flex  mb-8">
        <div className="flex sm:flex-2 justify-between ">
          <div className="flex ">
            <div className=" lg:w-16 w-10 lg:h-16 h-10 ">
              <img src={user.logo} alt="" className="rounded-full" />
            </div>
            <div className=" px-2 pt-2 pb-6 ">
              <h3 className="lg:text-lg text-xs sm:font-semibold font-bold">
                {user.name}
              </h3>
              <p className="lg:text-base text-xs font-light">
                {date}

                {/* {moment(parseInt(date)).format("lll")} */}
              </p>
              <p className="text-sm">{description}</p>
            </div>
          </div>
          <div>
            <img
              src={`/assets/images/${vehicleType}.png`}
              alt=""
              className="sm:w-12 w-10"
            />
          </div>
        </div>
        <div className=" sm:flex-3 md:space-x-6 space-x-3 xl:px-12 lg:px-6 sm:px-3 px-0  ">
          {" "}
          <div className="flex justify-center md:space-x-6 space-x-2 ">
            <div className="flex lg:text-3xl sm:text-xl text-lg  font-normal  space-x-1 items-center">
              <span className="text-4xl text-gray-500">
                <VscLocation />
              </span>{" "}
              <p className="text-gray-400 md:text-2xl sm:text-lg pt-3 text-sm font-semibold">
                {from?.name}
              </p>
            </div>
            <div className="h-full flex items-center  space-x-4">
              <span className="sm:text-5xl text-gray-400 pt-4 py-2 text-4xl">
                <BsArrowRight />
              </span>
            </div>
            <div className="flex lg:text-3xl sm:text-xl text-lg  font-normal  space-x-1 items-center">
              <span className="text-4xl text-gray-500">
                <VscLocation />
              </span>
              <p className="text-gray-400 md:text-2xl sm:text-lg pt-3 text-sm font-semibold">
                {to?.name}
              </p>
            </div>
          </div>
          <div className="flex justify-end  items-center ">
            {/* <Popup
              overlayStyle={{ background: "rgba(0,0,0,0.8)" }}
              modal
              trigger={
                <button className="bg-main text-white  md:text-lg sm:text-sm text-xs md:px-4  px-5 py-2 rounded-full mt-4">
                  Drop your box
                </button>
              }
            >
              <div className="flex flex-col border-2 px-14 w- py-4   rounded-lg bg-gray-100 shadow-sm">
                <div className="">
                  <img
                    src={user.logo}
                    alt="Public Drop"
                    className="h-48 rounded-full"
                  />
                  <h3 className="m-2 text-center ">{user.name}</h3>
                  <h3 className="text-sm m-4 ">
                    Mobile Number: {mobile_Number}
                  </h3>
                  <p className="text-xs font-medium"><span className="text-sm">Description : </span>{description} </p>

                  <CopyToClipboard text={mobile_Number}>
                    <button
                      className="bg-main w-full py-2 rounded-md text-white"
                      onClick={() => setstate("Copied!")}
                    >
                      {state}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </Popup> */}

            <Link to={`/journey/${_id}`}>
          <DropYourBoxButton/>
             </Link>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default JourneyListItem;
