import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import client from "./apollo/client";
import { ApolloProvider } from "@apollo/client";
import { PersistGate } from "redux-persist/integration/react";
import store from "./Redux/store";
import { persistStore } from "redux-persist";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter as Router } from "react-router-dom";

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App />
          </Router>
          <NotificationContainer />
        </PersistGate>
      </Provider>
    </ApolloProvider>
    ,
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
