import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="text-gray-600 body-font">
        <div className="container sm:px-20 px-6 py-24 mx-auto flex justify-between md:items-center lg:items-start md:flex-row  flex-col">
          <div className="w-64 flex-shrink-0  md:mx-0 mx-auto text-center md:text-left">
            <Link to="/">
              <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                <img
                  src="/newlogo.png"
                  alt=""
                  className="w-16 h-16 text-white   rounded-full"
                />
                <span className="ml-3 text-xl">Public Drop </span>
              </a>
            </Link>
            <p className="mt-2 text-md text-gray-500">
              India's First Public Logistic Network
            </p>
          </div>
          <div className=" flex  md:pl-20 sm:space-x-10  -mb-10 md:mt-0 mt-10 md:text-left ">
            <div className=" w-full px-4">
              <h2 className="title-font sm:font-medium font-semibold text-gray-900 tracking-widest sm:text-md text-xl mb-3">
                Main Links
              </h2>
              <ul className="list-none space-y-2 mb-10">
                <li>
                  <Link to="/create">
                    <a className="text-gray-900 hover:text-gray-400 hover:underline ">
                      Create Your Journey
                    </a>
                  </Link>
                </li>
                <li>
                    <Link to="/privacypolicy">
                  <a className="text-gray-900 hover:text-gray-400 hover:underline ">
                    Privacy Policy
                  </a>
                    </Link>
                </li>
                <li>
                  <Link to="contactus" >
                  <a className="text-gray-900 hover:text-gray-400 hover:underline ">
                    Contact Us
                  </a>
                  </Link>
                </li>
                <li>
                    <Link to="/aboutus">
                  <a className="text-gray-900 hover:text-gray-400 hover:underline  ">
                    Aboout Us
                  </a>
                    </Link>
                </li>
                <li>
                    <Link to="/support">
                  <a className="text-gray-900 hover:text-gray-400 hover:underline ">
                    Support
                  </a>
                    </Link>
                </li>
              </ul>
            </div>
            <div className=" w-full px-4">
              <h2 className="title-font sm:font-medium font-semibold text-gray-900 tracking-widest sm:text-md text-xl mb-3">
                Social Links
              </h2>
              <nav className="list-none space-y-2 mb-10">
                <li className="space-x-2 flex">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
                    <a target="_blank" href="https://www.instagram.com/publicdrop.in/" className="text-gray-900 hover:text-gray-400 hover:underline ">
                        Instagram 
                    </a>
                </li>
                <li className="flex space-x-2">
                <svg
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
                  <a target="_blank" href="https://www.facebook.com/Public-Drop-111321951251898" className="text-gray-900 hover:text-gray-400 hover:underline ">
                        Facebook 
                  </a>
               
                </li>
               
              </nav>
            </div>
          </div>
        </div>
        <div className="bg-gray-100">
          <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
            <p className="text-gray-500 text-sm text-center sm:text-left">
             <span className="text-base"> © 2021 Public Drop —</span>
              <a
                href="https://twitter.com/knyttneve"
                rel="noopener noreferrer"
                className="text-gray-600 ml-1"
                target="_blank"
              >
                @publicdrop.in
              </a>
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
              <a target="_blank" href="https://www.facebook.com/Public-Drop-111321951251898"  className="text-gray-500">
                <svg
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a className="ml-3 text-gray-500">
                <svg
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a className="ml-3 text-gray-500">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a className="ml-3 text-gray-500">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
