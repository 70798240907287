import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import CreateYourJourneyForm from "../Components/CreateYourJourneyForm";
import { useHistory } from "react-router-dom";

const CreateYourJourneyPage = () => {
  const history = useHistory();
  return (
    // <div className=" flex   items-center  flex-col">
    // <div className="fixed top-10 left-10 " onClick={() => history.goBack()}>
    //   <FiArrowLeft size="32" color="white" />
    // </div>
    //   <div className="h-36 flex flex-col bg-blue-500 w-full  justify-center items-center">
    //     <h2 className=" text-3xl mt-10 mb-10 text-white">
    //       Create Your Journey{" "}
    //     </h2>
    //   </div>
    //   <div className="-mt-10 py-6 ">
    //     <CreateYourJourneyForm />
    //   </div>
    // </div>

    <div className="h-screen overflow-scroll w-full">
      <div className="flex  w-full py-12  h-1/2 bg-blue-500">
        <div className="px-3 h-12 " onClick={() => history.goBack()}>
          <FiArrowLeft size="32" color="white" />
        </div>
        <div className=" text-white flex text-lg justify-center w-full  ">
          <h1>Create Your Journey</h1>
        </div>
      </div>
      <div className="sm:-mt-onethird -mt-half max-w-xl px-8 mx-auto"><CreateYourJourneyForm /> </div>
    </div>
  );
};

export default CreateYourJourneyPage;
