import React from "react";
import Banner from "./Banner";
import CreateYourJourneyForm from "./CreateYourJourneyForm";

const UpperSection = () => {
  return (
    <>
      <div>
        <div className="h-full mb-10">
          <Banner firstLine={"India's First "} size="text-3xl" secondLine={"Public Logistic Network"}  />
        </div>
      </div>
    </>
  );
};

export default UpperSection;
