import React from 'react'

const Blogs = (props) => {
const {src,headLine, content }=props;
    return (
        <div className=" lg:w-1/4 md:w-64   shadow-xl  rounded-lg  z-10">
            <div className="">
                <div>
                        <img src={`/assets/images/${src}.jpeg`} alt="" className="w-full  rounded-t-lg" />
                </div>
                <div className="p-2">
                    <h1 className="text-xl p-2" >{headLine}</h1 >
                    <p>{content}</p>
                </div>
            </div>
        </div>
    )
}

export default Blogs
