import React from 'react'
import { Link } from 'react-router-dom'

const AboutUs = () => {
    return (
        <div className="md:px-20 px-8 py-10">

            <h1 className="flex justify-center pb-5">Public Drop - About Us</h1>

Welcome to Public Drop, your number one source for all things [product]. We're dedicated to giving you the very best , with a focus on Helping People , Making People Self dependent and giving the habit of helping each other.
<br />
<br />
Founded in 2021 by Tahsin Chouhan, Public Drop has come a long way from its beginnings in Raipur. When Tahsin Chouhan first started out, his passion for "India's First Public Logistic Network"drove them to  quit day job, do tons of research, so that Public Drop can offer you "India's First Public Logistic" Network  We now serve customers all over the country, and are thrilled that we're able to turn our passion into my own website.
<br />
<br />
I hope you enjoymy products as much as I enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us at "publicdrop.connect@gmail.com"
<br />
<br />
Sincerely,
<br />
Tahsin Chouhan
<br />

        </div>
    )
}

export default AboutUs
