import React from "react";
import Home from "./Pages/Home";
import { Switch, Route, useLocation } from "react-router-dom";
import CreateYourJourneyPage from "./Pages/CreateYourJourneyPage";
import SlideRoutes from "react-slide-routes";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import AboutUs from "./Pages/AboutUs";
import Support from "./Pages/Support";
import ContactUs from "./Pages/ContactUs";
import Details from "./Pages/Details";
import JourneyList from "./Components/JourneyList";
import HomeLayout from "./layouts/HomeLayout";
const HomePage = () => {
  return (
    <HomeLayout>
      <Home />
    </HomeLayout>
  );
};
const JourneyPage = () => {
  return (
    <HomeLayout>
      <JourneyList />
    </HomeLayout>
  );
};

const App = () => {
  const location = useLocation();
  return (
    <Switch>
      <SlideRoutes location={location}>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/create" component={CreateYourJourneyPage} />
        <Route exact path="/journey/:journeyId" component={Details} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/send" component={JourneyPage} />
      </SlideRoutes>
    </Switch>
  );
};

export default App;
