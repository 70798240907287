import React from "react";
import Popup from "reactjs-popup";
import { useMutation } from "@apollo/client";
import GOOGLE_SIGNIN from "../apollo/mutation/googleSignIn";
import { useDispatch } from "react-redux";
import { setAuth } from "../Redux/store";
import { FcGoogle } from "react-icons/fc";
import GoogleLogin from "react-google-login";

const LoginButton = ({ title, onSubmit }) => {
  const dispatch = useDispatch();
  const [googleSignIn] = useMutation(GOOGLE_SIGNIN, {
    onCompleted: (res) => {
      console.log({ res });
      const { payload: user, token } = res.googleSignIn;
      dispatch(setAuth({ user, token }));
      if (onSubmit) {
        console.log("submittable");
        setTimeout(() => {
          onSubmit();
        }, 1000);
        //   onsubmit()
      }
    },
  });

  return (
    <GoogleLogin
      clientId={process.env.NODE_ENV === "production" ? "185690993989-uodp6amf1unkks86jr0s5ltoubaou5n2.apps.googleusercontent.com" :"185690993989-jlvvaa197pouh5vjjlmr5m98vc00r9df.apps.googleusercontent.com"}
      onSuccess={(response) => {
        googleSignIn({
          variables: {
            name: response.profileObj.name,
            email: response.profileObj.email,
            logo: response.profileObj.imageUrl,
            googleId: response.profileObj.googleId,
          },
        });
      }}
      render={(renderProps) => (
        <Popup
          overlayStyle={{ background: "rgba(0,0,0,0.8)" }}
          modal
          trigger={
            <button className="text-white sm:px-10 px-4 sm:text-base bg-main shadow text-sm md:py-2 py-2 font-semibold rounded-full">
              {title}
            </button>
          }
        >
          <div className="flex flex-col border-2 px-16 py-12   rounded-lg bg-gray-100 shadow-sm">
            <div className="text-8xl text-gray-500 pb-5  mx-auto">
              <img src="/newlogo.png" alt="Public Drop" className="h-48" />
            </div>
            <div className="mx-auto">
              <button
                onClick={renderProps.onClick}
                className="sm:px-10 px-4 sm:text-base flex  bg-gray-50 text-gray-400 shadow-lg  text-sm md:py-3 py-3 font-medium rounded-md"
              >
                <span className="px-3 flex  text-2xl">
                  <FcGoogle />
                </span>{" "}
                Sign In with google
              </button>
            </div>
          </div>
        </Popup>
      )}
      buttonText="Login"
      onFailure={(response) => {
        console.log(response);
      }}
    />
  );
};

export default LoginButton;
