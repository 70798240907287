import React from "react";

const CreateJourneyButton = ({onSubmit}) => {
  return (
    <button
      type="submit"
      onClick={onSubmit}
      className="bg-main text-white font-semibold  md:text-lg sm:text-sm text-sm md:px-4  px-5 py-3 rounded-full shadow-2xl mt-2"
    >
      Create your journey
    </button>
  );
};

export default CreateJourneyButton;



{/* <button className="bg-main text-white  md:text-lg sm:text-sm text-xs md:px-4  px-5 py-2 rounded-full mt-4">
Drop your box
</button> */}