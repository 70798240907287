import { gql } from "@apollo/client";

const GET_PLACES = gql`
  query getPlaces($page: Int, $limit: Int, $search: String) {
    getPlaces(page: $page, limit: $limit, search: $search) {
      _id
      name
      state
      status
      createdAt
      updatedAt
    }
  }
`;
export default GET_PLACES;
