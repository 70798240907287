import { useLazyQuery, useMutation } from "@apollo/client";
import Autocomplete from "react-autocomplete";
import React, { useCallback, useEffect } from "react";
import { VscLocation } from "react-icons/vsc";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import INSERT_JOURNEY from "../apollo/mutation/insertJourney";
import GET_PLACES from "../apollo/query/getPlaces";
import store, { resetForm, setForm, updateForm } from "../Redux/store";
import CreateJourneyButton from "./CreateJourneyButton";
import { CgCalendarDates } from "react-icons/cg";
import { MdPermContactCalendar } from "react-icons/md";
import LoginButton from "./LoginButton";

const CreateYourJourneyForm = () => {
  const dispatch = useDispatch();

  const { accountId, journeyform: state } = useSelector((st) => ({
    journeyform: st?.journeyform,
    accountId: st?.auth?.user?._id,
  }));
  const [insertJourney] = useMutation(INSERT_JOURNEY, {
    onCompleted: (response) => {
      console.log("journey has been created");
      console.log(response);
      NotificationManager.success(
        "Success message",
        "Your Journey has sucessfully created"
      );
      dispatch(resetForm());
    },
    onError: (error) => {
      console.log("Error occurs");
      console.log(error);
    },
  });

  const [getPlaces, { data: placeData }] = useLazyQuery(GET_PLACES, {
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    const variables = {
      page: 1,
      limit: 10,
    };

    if (state.fromText !== "") {
      variables.search = state.fromText;
    }
    if (state.toText !== "") {
      variables.search = state.toText;
    }

    console.log(variables);
    getPlaces({
      variables,
    });
  }, [getPlaces, state.fromText, state.toText]);

  const onChange = useCallback(
    (e) => {
      console.log({ value: e.target.value });
      dispatch(setForm({ field: e.target.name, value: e.target.value }));
    },
    [dispatch]
  );

  const onSubmit = (e) => {
    const myaccountId = store.getState()?.auth?.user?._id;
    if (!myaccountId) {
      NotificationManager.error("Login Error", "You are not LoggedIn");
      return;
    }

    if (state.startingPoint === "") {
      NotificationManager.error("Form Error", "Enter your Location");
      return;
    }
    if (state.endPoint === "") {
      NotificationManager.error("Form Error", "Enter your Destination");
      return;
    }

    if (state.startingPoint === state.endPoint) {
      NotificationManager.error(
        "Form Error",
        "Your Location Matches with Destination"
      );
      return;
    }

    if (state.mobile_Number === "") {
      NotificationManager.error("Form Error", "Enter your Mobile Number");
      return;
    }

    if (state.date === "") {
      NotificationManager.error("Form Error", "Enter your Journey Date");
      return;
    }

    const variables = {
      userId: myaccountId,
      startingPoint: state.startingPoint,
      endPoint: state.endPoint,
      mobile_Number: state.mobile_Number,
      description:state.description,
      date: state.date,
      vehicleType: state.vehicleType,
    };
    console.log(variables);
    insertJourney({
      variables,
      refetchQueries: ["getJourneys"],
    });
  };

  const results = placeData?.getPlaces || [];

  return (
    <div
      className="shadow-md sm:space-y-6 space-y-1 rounded-lg  mx-auto sm:px-10 px-6 py-8 border-1 bg-white"
      style={{ minwidth: "18rem" }}
    >
      <div className=" 
       space-y-5  ">
        <div className="flex flex-col  ">
          {/**
           * top
           */}
          <div className=" ">
            <div className="flex  -ml-2">
              <VscLocation size="20" />
              <span className="flex items-end text-gray-500"> From</span>
            </div>
            <Autocomplete
              inputProps={{
                placeholder: "Your location",
                className:
                  "outline-none border sm:text-lg text-sm border-none rounded-md leading-tight m-0  w-full  h-12 appearance-none font-medium text-xl",
                style: { minWidth: 230 },
              }}
              renderMenu={(items, value, style) => {
                return (
                  <div
                    className="max-h-40 overflow-scroll absolute z-10 large:w-96  sm:w-80 w-32  border  overflow-x-hidden my-menu"
                    children={items}
                  />
                );
              }}
              getItemValue={(item) => item.name}
              items={results}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "lightgray" : "white",
                  }}
                  key={item._id}
                  className="border-b flex items-center px-4 h-10"
                >
                  {item.name}
                </div>
              )}
              value={state.fromText}
              onChange={(e, _text) => {
                dispatch(setForm({ field: "fromText", value: _text }));
              }}
              onSelect={(text, data) => {
                dispatch(
                  updateForm({
                    loading: false,
                    fromText: data.name,
                    startingPoint: data._id,
                  })
                );
              }}
            />
            <hr />
          </div>

          {/* <div className="lg:text-6xl md:text-5xl sm:text-3xl text-4xl  flex pt-7 items-center text-gray-400 ">
            <BsArrowRight />
          </div> */}
          <div className=" mt-5">
            <div className="flex  -ml-2">
              <VscLocation size="20" />
              <span className="flex items-end text-gray-500"> To</span>
            </div>
            <Autocomplete
              inputProps={{
                placeholder: "Your Destination",
                className:
                  "outline-none border sm:text-lg text-sm border-none rounded-md leading-tight m-0  w-full  h-12 appearance-none font-medium text-xl",
                style: { minWidth: 230 },
              }}
              renderMenu={(items, value, style) => {
                return (
                  <div
                    className="max-h-40 absolute z-10 large:w-80 lg:w-64 md:w-50 sm:w-40 w-32   overflow-scroll border overflow-x-hidden my-menu"
                    children={items}
                  />
                );
              }}
              getItemValue={(item) => item.name}
              items={results}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{
                    background: isHighlighted ? "lightgray" : "white",
                  }}
                  key={item._id}
                  className="border-b  flex items-center px-4  h-10"
                >
                  {item.name}
                </div>
              )}
              value={state.toText}
              onChange={(e, _text) => {
                dispatch(setForm({ field: "toText", value: _text }));
              }}
              onSelect={(text, data) => {
                dispatch(
                  updateForm({
                    loading: false,
                    toText: data.name,
                    endPoint: data._id,
                  })
                );
              }}
            />
            <hr />
          </div>
        </div>

        <div className="flex flex-col ">
          <div className="flex  -ml-2">
            <CgCalendarDates size='20' /> &nbsp;  
            <span className="flex items-end text-gray-500">
              {"    "}
              Date of Journey{" "}
            </span>
          </div>

          <input
            type="datetime-local"
            className="outline-none border sm:text-lg  border-none rounded-md leading-tight m-0  w-10/12  h-12 appearance-none font-medium text-xl text-black"
            name="date"
            onChange={onChange}
            required
            value={state.date}
            id="date"
          />
          {console.log(state.date)}
          <hr />
        </div>
        <div className="flex flex-col">
          <div className="flex  -ml-2">
            <MdPermContactCalendar size="20" />
            &nbsp;  
            <span className="flex items-end text-gray-400">    
              Mobile No.
            </span>
          </div>

          <input
            type="number"
            name="mobile_Number"
            required
            placeholder="Enter your Mobile no"
            onChange={onChange}
            value={state.mobile_Number}
            className="outline-none border sm:text-lg text-sm font-medium  border-none rounded-md leading-tight m-0  w-full  h-12 appearance-none text-xl "
          />
          <hr />
        </div>
        <div>
          <textarea  onChange={onChange} name="description" id=""  rows="3" value={state.description} placeholder="Enter Journey Description" className="border-b-2 w-full capitalize  font-medium placeholder-gray-400  text-xl"></textarea>
        </div>
      </div>


      <div className=" mx-auto  justify-between  items-center ">
        <div className="mt-10">
          <span className="flex items-end text-gray-500">
            {"    "}
            Select Your Travel Vehicle{" "}
          </span>

          <div className="flex md:space-x-16  justify-evenly">
            <div className="flex items-center sm:space-x-3 space-x-1 ">
              <input
                type="radio"
                name="vehicleType"
                required
                onChange={onChange}
                value="motorbike"
                checked={state.vehicleType === "motorbike"}
              />
              <img
                src={`/assets/images/motorbike.png`}
                alt=""
                className="sm:h-12 h-9"
              />
            </div>
            <div className="flex items-center sm:space-x-3 space-x-1 ">
              <input
                type="radio"
                name="vehicleType"
                required
                onChange={onChange}
                value="car"
                checked={state.vehicleType === "car"}
              />
              <img
                src={`/assets/images/car.png`}
                alt=""
                className="sm:h-12 h-9"
              />
            </div>
            <div className="flex items-center sm:space-x-3 space-x-1 ">
              <input
                type="radio"
                name="vehicleType"
                required
                onChange={onChange}
                value="bus"
                checked={state.vehicleType === "bus"}
              />
              <img
                src={`/assets/images/bus.png`}
                alt=""
                className="sm:h-12 h-9"
              />
            </div>
            <div className="flex items-center sm:space-x-3 space-x-1 ">
              <input
                type="radio"
                name="vehicleType"
                required
                onChange={onChange}
                value="train"
                checked={state.vehicleType === "train"}
              />
              <img
                src={`/assets/images/train.png`}
                alt=""
                className="sm:h-9 h-7"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-3 sm:pt-0 mt-5">
          {accountId ? (
            <CreateJourneyButton onSubmit={onSubmit} />
          ) : (
            <LoginButton title="Create your Journey" onSubmit={onSubmit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateYourJourneyForm;
