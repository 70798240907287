import { gql } from "@apollo/client";

const INSERT_JOURNEY = gql`
  mutation insertJourney(
    $userId: ID!
    $startingPoint: ID!
    $endPoint: ID!
    $vehicleType: String!
    $date:String!
    $mobile_Number: String!
    $description: String
  ) {
    insertJourney(
      userId: $userId
      startingPoint: $startingPoint
      endPoint: $endPoint
      vehicleType: $vehicleType
      mobile_Number: $mobile_Number
      description:$description
      date:$date
    ) {
      _id
      userId
      status
      startingPoint
      endPoint
      vehicleType
      description
      createdAt
      updatedAt
      mobile_Number
      description
      date
    }
  }
`;
export default INSERT_JOURNEY;
